
import * as THREE from "../../../libs/three.js/build/three.module.js";
import {EventDispatcher} from "../../EventDispatcher.js";
import { OrbitControls } from "../../navigation/OrbitControls.js";

 
/**
 * Controls for oriented image viewing
 */
export class OrientedImageControls extends OrbitControls{

	constructor(viewer, imageParamsPath) {
		super(viewer);
		// Release the viewer when the user moves the camera
		this.addEventListener('orbit', this.release);
		this.addEventListener('pan', this.release);

		// Define download button
		this.elDownload = $(`<button type="button" style="position: absolute; bottom: 10px; right: 10px; z-index: 1000">Download Image</button>`)
		this.elDownload.click(() => {
			window.location = `${imageParamsPath}/../${this.image.id}`;
		});
	}

	/**
	 * Determines if the controls are locked onto an image
	 * @returns whether or not the image is null
	 */
	hasSomethingCaptured() {
		return this.image != null;
	}

	/**
	 * Captures an oriented image for controls
	 * @param {*} image the image to capture
	 */
	capture(image){
		if(this.hasSomethingCaptured()){
			return;
		}

		this.image = image;

		this.originalFOV = this.viewer.getFOV();
		this.originalControls = this.viewer.getControls();

		const elCanvas = this.viewer.renderer.domElement;
		const elRoot = $(elCanvas.parentElement);

		//TODO: elRoot.append(bullshit)
		elRoot.append(this.elDownload);

		this.viewer.setControls(this);
		this.viewer.scene.overrideCamera = this.shearCam;
	}

	/**
	 * Releases oriented image, returns to normal controls
	 */
	release(){
		this.image = null;

		this.viewer.scene.overrideCamera = null;

		this.viewer.setFOV(this.originalFOV);
		this.viewer.setControls(this.originalControls);
	}

}